
import { getPropertyChangeRequestDefaults } from "@/helpers/getPropertyChangeRequestDefaults";
import Vue from "vue";
import ChangeRequestFileUpload from "../AttachmentUploader/ChangeRequestFileUpload.vue";
import {
  propertyChangeRequestMapGetters,
  propertyChangeRequestMapState
} from "@/store/modules/propertychangerequest";
import { mortgageMapActions } from "@/store/modules/mortgage";
import { ITableWrapperColumns } from "@/types";
import { tableColumnsListMortgages } from "./mortgageListView";
import { objectDifference } from "@/helpers";
import { IMortgage } from "@/store/modules/mortgage/types";
import MortgageCompanyPickerModal from "@/components/MortgageCompanyPicker/MortgageCompanyPickerModal.vue";
import { cloneDeep, get, set } from "lodash";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
interface IData {
  EndorsementsLimitsCheckBox: string;
  activeNames: any[];
  CoverageLimitsRadioButton: string;
  EndorsementsLimitsRadioButton: string;
  MortgageRadioButton: string;
  BillMortgageRadioButton: string;
  CoverageLimitsTextAreaLimitNumber: string;
  EndorsementsLimitsTextAreaLimitNumber: string;
  MortgageTextAreaLimitNumber: string;
  MortgageListRadioButton: string;
  consistencyRadioButton: string;
  hasAttachment: boolean;
  message: string;
  tableColumns: ITableWrapperColumns;
  selectedMortgage: IMortgage;
  error: string;
  loading: boolean;
  consistency: boolean;
  activeConsistency: string;
  myAccordionCategory: any[];
  mortgageData: any[];
  mortgageDataAvailable: boolean;
  touchedContent: {
    visible: boolean;
    name: string;
    ref: string;
  };
  hasValidCredentials: boolean;
  mortgageRemarks: string;
  disableMortgageCompanyName: boolean;
  maxCharacterLength: number;
  endorsementDescriptionCharactersLeft: number;
  descriptionCharactersLeft: number;
}

const mortgageDefault = {
  _id: "",
  mortgageName: "",
  shortName: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  AICode: "",
  createdOn: new Date(),
  modifiedOn: new Date(),
  deleted: false
};
export default Vue.extend({
  name: "PropertyChangeRequestActions",
  components: {
    ChangeRequestFileUpload,
    MortgageCompanyPickerModal,
    CustomAlert
  },

  data(): IData {
    return {
      hasValidCredentials: false,
      EndorsementsLimitsCheckBox: "",
      tableColumns: tableColumnsListMortgages,
      activeNames: [],
      selectedMortgage: mortgageDefault,
      CoverageLimitsRadioButton: "",
      EndorsementsLimitsRadioButton: "",
      MortgageRadioButton: "",
      BillMortgageRadioButton: "",
      consistencyRadioButton: "",
      CoverageLimitsTextAreaLimitNumber: "",
      EndorsementsLimitsTextAreaLimitNumber: "",
      MortgageTextAreaLimitNumber: "",
      hasAttachment: false,
      mortgageDataAvailable: false,
      message: "",
      error: "",
      mortgageData: [],
      MortgageListRadioButton: "",
      consistency: false,
      activeConsistency: "0",
      loading: false,
      touchedContent: {
        visible: false,
        name: "",
        ref: ""
      },
      myAccordionCategory: [
        {
          visible: false,
          name: "Coverage/Limits",
          ref: "coverage"
        },
        {
          visible: false,
          name: "Endorsements",
          ref: "endorsement"
        },
        {
          visible: false,
          name: "Mortgage Change",
          ref: "mortgage"
        },
        {
          visible: false,
          name: "Document Upload",
          ref: "document"
        }
      ],
      mortgageRemarks: "",
      disableMortgageCompanyName: false,
      maxCharacterLength: 395,
      endorsementDescriptionCharactersLeft: 395,
      descriptionCharactersLeft: 395
    };
  },
  props: {
    propertyChangeRequestData: {
      type: Object
    },
    changeRequestType: {
      type: String
    },
    isEdit: {
      required: false,
      type: Boolean
    }
  },
  methods: {
    //TODO: refactor this and use validateFields helper function. just needs modification to accommodate array checks being done here
    validateFields(refs: Record<string, any>) {
      let result: any = null;
      if (refs) {
        for (const ref in refs) {
          if (ref && ref.substring(0, 6) === "field_") {
            const element = refs[ref];
            if (Array.isArray(element)) {
              for (const el of element) {
                if (el.$v) {
                  result = el.$v;
                }
              }
            } else if (element.$v) {
              result = element.$v;
            }
          }
        }
      }
      if (result) {
        return result.$error;
      }
      return false;
    },
    updateIsValid() {
      this.hasValidCredentials = this.validateFields(this.$refs);
    },
    ...mortgageMapActions(["getMortgages"]),
    handleOnInputChange() {
      const mortgage: any = get(this.editing?.data, "mortgage", {});
      const result = !!(
        mortgage.address.length &&
        mortgage.mortgage.length &&
        mortgage.mortgageAddress.city.length &&
        mortgage.mortgageAddress.state.length &&
        mortgage.mortgageAddress.zipCode.length
      );
      this.$emit("disableMortgageSubmitButton", !result);
    },
    async scrubMortgageData(dataReceived: any): Promise<any> {
      this.selectedMortgage = dataReceived;
      if (this.enableMortgageFieldWhenOther) {
        (this.$refs.field_mortgage as any)[0].focusFieldRef();
      }
      this.setMortgageData(dataReceived);
      this.$modal.hide("selectMortgageModal");
    },
    handleMortgageRadioChange(value: any) {
      this.$emit("updateChangeRequestData", {
        fieldKey: "data.actions.mortgageAction",
        value: value
      });
      this.disableMortgageCompanyName = false;
      this.$modal.show("selectMortgageModal");
    },
    handleAttachmentDelete(files: Record<string, any>[]) {
      this.$emit("handleAttachmentDelete", files);
    },
    attachmentUploadHandler(data: any) {
      this.$emit("attachmentData", data);
    },
    handleChangeCheckBox($event: any, data: any) {
      if (data.ref === "mortgage") {
        this.disableMortgageCompanyName = true;
        this.$emit("disableMortgageSubmitButton", $event);
      }
      this.touchedContent = data;
      if (!data.visible) {
        return (data.visible = true);
      }
      this.$modal.show("removeDataModal");
    },
    closeModal(): any {
      return this.$modal.hide("removeDataModal");
    },
    setMortgageData(mortgageData: any) {
      const insuredKeys = [
        "data.mortgage.mortgage",
        "data.mortgage.mortgageAddress.state",
        "data.mortgage.mortgageAddress.city",
        "data.mortgage.mortgageAddress.zipCode",
        "data.mortgage.address",
        "data.actions.mortgageAction",
        "data.mortgage.listed",
        "data.mortgage.billed"
      ];
      const { address, city, mortgageName, state, zipCode } = mortgageData;
      const dataObject = {
        data: {
          mortgage: {
            mortgage: mortgageName,
            mortgageAddress: {
              state,
              city,
              zipCode
            },
            address
          },
          actions: {
            mortgageAction: this.MortgageRadioButton
          }
        }
      };
      for (let i = 0; i < insuredKeys.length; i++) {
        this.$emit("updateChangeRequestData", {
          fieldKey: `${insuredKeys[i]}`,
          value:
            get({ ...dataObject, ...mortgageData }, `${insuredKeys[i]}`, "") ||
            ""
        });
      }
    },
    clearSelectedMortgage() {
      this.MortgageRadioButton = "";
      const selectedMortgageKeys = [
        "mortgageName",
        "city",
        "state",
        "address",
        "zipCode"
      ];
      const clonedSelectedData = cloneDeep(this.selectedMortgage);
      for (const key of selectedMortgageKeys) {
        set(clonedSelectedData, key, "");
      }
      this.BillMortgageRadioButton = "";
      this.MortgageListRadioButton = "";
      this.selectedMortgage = clonedSelectedData;
      this.setMortgageData([]);
    },
    confirmModal(): any {
      this.touchedContent.visible = !this.touchedContent.visible;
      this.$modal.hide("removeDataModal");
      // resetting accordion contents
      // resetting c  overage contents
      if (this.touchedContent.ref == "coverage") {
        this.CoverageLimitsRadioButton = "";
        this.CoverageLimitsTextAreaLimitNumber = "";
        this.$emit("updateChangeRequestData", {
          fieldKey: "data.actions.limitAction",
          value: ""
        });
        this.$emit("updateChangeRequestData", {
          fieldKey: "data.description.limitDescription",
          value: ""
        });
      }
      // resetting endorsement contents
      if (this.touchedContent.ref == "endorsement") {
        this.EndorsementsLimitsRadioButton = "";
        this.EndorsementsLimitsTextAreaLimitNumber = "";
        this.$emit("updateChangeRequestData", {
          fieldKey: "data.description.endorsementDescription",
          value: ""
        });

        this.$emit("updateChangeRequestData", {
          fieldKey: "data.actions.endorsementAction",
          value: ""
        });
      }
      if (this.touchedContent.ref == "mortgage") {
        this.clearSelectedMortgage();
      }
    },
    handleConsistencyChange() {
      if (this.consistency === true) {
        this.$modal.show("removeDataModal");
      }
      this.consistency = !this.consistency;
    },
    handleCharacterCountDown(characters: string) {
      if (characters.length >= this.maxCharacterLength) return 0;
      return this.maxCharacterLength - characters.length;
    },
    handleEndorsementLimitDescription(e: any) {
      this.endorsementDescriptionCharactersLeft = this.handleCharacterCountDown(
        e
      );
    },
    handleLimitDescription(e: any) {
      this.descriptionCharactersLeft = this.handleCharacterCountDown(e);
    }
  },
  computed: {
    enableUpload(): boolean {
      return !this.myAccordionCategory.some((acc: any) => acc.visible);
    },
    limitDescription(): string {
      return get(
        this.propertyChangeRequestData.data,
        "description.limitDescription",
        ""
      );
    },
    endorsementDescription(): string {
      return get(
        this.propertyChangeRequestData.data,
        "description.endorsementDescription",
        ""
      );
    },
    ...propertyChangeRequestMapState(["editing", "makingApiRequest"]),
    ...propertyChangeRequestMapGetters(["getEditingPropertyChangeRequest"]),
    updatedFields(): any {
      const edited = this.getEditingPropertyChangeRequest;
      const original = getPropertyChangeRequestDefaults({});
      return edited && original ? objectDifference(edited, original) : {};
    },
    enableMortgageFieldWhenOther(): boolean {
      return !!(
        this.selectedMortgage.mortgageName === "OTHER" &&
        this.selectedMortgage.address === "OTHER" &&
        this.selectedMortgage.city === "OTHER" &&
        this.selectedMortgage.state === "OTHER" &&
        this.selectedMortgage.zipCode === "OTHER"
      );
    }
  },

  watch: {
    updatedFields: {
      handler() {
        this.updateIsValid();
        this.$emit("actionsFieldsValid", this.hasValidCredentials);
      }
    },
    "propertyChangeRequestData.data.mortgage.remarks": {
      handler(val) {
        this.mortgageRemarks = val;
      },
      immediate: true
    }
  },
  mounted() {
    this.MortgageRadioButton = this.propertyChangeRequestData.data.actions
      ? this.propertyChangeRequestData.data.actions.mortgageAction
      : "";
    this.EndorsementsLimitsRadioButton = this.propertyChangeRequestData.data
      .actions
      ? this.propertyChangeRequestData.data.actions.endorsementAction
      : "";
    this.CoverageLimitsRadioButton = this.propertyChangeRequestData.data.actions
      ? this.propertyChangeRequestData.data.actions.limitAction
      : "";
    this.BillMortgageRadioButton = this.propertyChangeRequestData.data.actions
      ? this.propertyChangeRequestData.data.mortgage.billed
      : "";
    this.MortgageListRadioButton = this.propertyChangeRequestData.data.actions
      ? this.propertyChangeRequestData.data.mortgage.listed
      : "";
    this.CoverageLimitsTextAreaLimitNumber = this.propertyChangeRequestData.data
      .actions
      ? this.propertyChangeRequestData.data.mortgage.listed
      : "";
    this.hasAttachment = this.propertyChangeRequestData.attachments.length > 0;
    // open actions accordion if data is already present
    if (this.MortgageRadioButton) {
      this.myAccordionCategory[2].visible = true;
    }
    if (this.CoverageLimitsRadioButton) {
      this.myAccordionCategory[0].visible = true;
    }
    if (this.EndorsementsLimitsRadioButton) {
      this.myAccordionCategory[1].visible = true;
    }
    if (this.hasAttachment) {
      this.myAccordionCategory[3].visible = true;
    }
  }
});
